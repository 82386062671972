var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "", hideCloseButton: _vm.isLoaderActive },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoaderActive,
                expression: "!isLoaderActive",
              },
            ],
            staticClass: "f4 white",
          },
          [_vm._v("Select Audio Voicing")]
        ),
      ]),
      _c("template", { slot: "body" }, [
        _vm.isLoaderActive
          ? _c("div", { staticClass: "w-100 flex flex-column items-center" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Converting to Audio ....",
                      expression: "loaderText === 'Converting to Audio ....'",
                    },
                  ],
                  staticClass: "flex w-100 justify-between items-center",
                },
                [
                  _c("div", { staticClass: "text-loader" }),
                  _c(
                    "i",
                    {
                      staticClass: "material-icons light-gray f2 v-mid pa1 ml5",
                    },
                    [_vm._v("forward")]
                  ),
                  _c("div", { staticClass: "audio-loader" }),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Generating AI images ....",
                      expression: "loaderText === 'Generating AI images ....'",
                    },
                  ],
                  staticClass: "flex w-100 justify-center items-center",
                },
                [_c("div", { staticClass: "img-loader" })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Creating visuals ...",
                      expression: "loaderText === 'Creating visuals ...'",
                    },
                  ],
                  staticClass: "flex w-100 justify-center items-center",
                },
                [_c("div", { staticClass: "tag-loader" })]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loaderText === "Creating visuals ...",
                      expression: "loaderText === 'Creating visuals ...'",
                    },
                  ],
                  staticClass: "silver mt3 tc f6",
                  staticStyle: { "max-width": "350px" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.tagCount) + " / " + _vm._s(_vm.totalTagsCount)
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "text-display mt4",
                  attrs: { "data-content": _vm.loaderText },
                },
                [_vm._v(_vm._s(_vm.loaderText))]
              ),
              _c(
                "span",
                {
                  staticClass: "silver mt3 tc f6",
                  staticStyle: { "max-width": "350px" },
                },
                [_vm._v(_vm._s(_vm.randomLoadingText))]
              ),
            ])
          : _c("div", { staticClass: "flex flex-column items-center min-w5" }, [
              _c("div", { staticClass: "flex justify-between items-center" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ba bw1 br4 b--transparent bg-black-20 flex items-center",
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "f6 pv1 ph2 silver link pointer mr1",
                        class:
                          _vm.selectedTab === "BASIC"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedTab === "BASIC" },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = "BASIC"
                            },
                          },
                        }),
                        _vm._v("Basic Voices\n          "),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "f6 pv1 ph2 silver link pointer mr1",
                        class:
                          _vm.selectedTab === "PREMIUM"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedTab === "PREMIUM" },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = "PREMIUM"
                            },
                          },
                        }),
                        _vm._v("Pro voices 🌟\n          "),
                      ]
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "f6 pv1 ph2 silver link pointer mr1",
                        class:
                          _vm.selectedTab === "CLONING"
                            ? "ba br4 b--transparent bg-black-80 light-gray"
                            : "",
                      },
                      [
                        _c("input", {
                          staticClass: "input-reset",
                          attrs: { type: "radio" },
                          domProps: { checked: _vm.selectedTab === "CLONING" },
                          on: {
                            click: function ($event) {
                              _vm.selectedTab = "CLONING"
                            },
                          },
                        }),
                        _vm._v("Build your voice\n          "),
                      ]
                    ),
                  ]
                ),
              ]),
              _vm.selectedTab === "PREMIUM"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/tts.svg"),
                          alt: "",
                          width: "150",
                          height: "150",
                        },
                      }),
                      _c("VoiceSelection", {
                        attrs: {
                          buttonTitle: "Create Video",
                          quality: _vm.selectedTab,
                        },
                        on: { "voice-details": _vm.handleVoiceDetails },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedTab === "BASIC"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/tts.svg"),
                          alt: "",
                          width: "150",
                          height: "150",
                        },
                      }),
                      _c("VoiceSelection", {
                        attrs: {
                          buttonTitle: "Create Video",
                          quality: _vm.selectedTab,
                        },
                        on: { "voice-details": _vm.handleVoiceDetails },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedTab === "CLONING"
                ? _c("div", { staticClass: "w-100" }, [
                    _c(
                      "div",
                      { staticClass: "white flex justify-center f3 mt4" },
                      [_vm._v("COMING SOON!!")]
                    ),
                    _c(
                      "div",
                      { staticClass: "silver flex justify-center f6 mt4" },
                      [
                        _vm._v(
                          "You have recorded 0 / 25 samples so far (4.00%)"
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "progress-wrapper mt2" }, [
                      _c("div", { staticClass: "progress" }, [
                        _c("div", {
                          staticClass: "progress-bar",
                          style: "width:" + 0 + "%",
                        }),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-adori-light-gray pa4 flex flex-column items-center sample-sentence-box",
                      },
                      [
                        _c("div", { staticClass: "f6 white" }, [
                          _vm._v("SAY THE SENTENCE BELOW"),
                        ]),
                        _c("div", { staticClass: "f4 white mt3" }, [
                          _vm._v(
                            "You wouldn't have done that if there had not been something"
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-adori-medium-gray record-box flex justify-center",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "white bg-adori-red bn br3 flex justify-center items-center ph3 pv2 ma2",
                          },
                          [
                            _c(
                              "i",
                              { staticClass: "material-icons white f3" },
                              [_vm._v(" mic ")]
                            ),
                            _vm._v("\n            Record\n          "),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }