








































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import VoiceSelection from '@/components/Common/VoiceSelection.vue'
import randomText from '@/utils/loadingTexts'

@Component({
  components: {
    VoiceSelection,
  },
})
export default class ModalLoader extends Vue {
  @Prop(Boolean) isLoaderActive!: boolean
  @Prop(String) loaderText!: string
  @Prop() totalTagsCount!: any
  @Prop() tagCount!: any

  randomLoadingText = 'Do not run! We are your friends!'
  selectedTab = 'BASIC'
  handleVoiceDetails(voiceDetails: any) {
    this.$emit('voice-details', voiceDetails)
  }

  created() {
    this.randomText()
  }

  randomText() {
    setTimeout(() => {
      this.randomLoadingText = randomText()
      this.randomText()
    }, 7000)
  }
}
